.App {
  font-family: 'Asap', sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  font-family: 'DM Serif Text', serif;
  font-size: 27pt;
  /* width: 330px; */
  line-height: 28pt;
  padding: 30px;
}

.info{
  padding: 30px;
  font-size: 10pt;
}

.underline{
  text-decoration: underline;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
